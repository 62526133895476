/**
 * Created by eitanr on 6/24/14.
 */
define([
    'lodash',
    'warmupUtilsLib',
    'layout/util/layout',
    'layout/specificComponents/svgShape/svgScaler'
], function (_,
             warmupUtilsLib,
             /** layout.layout */layout,
             svgScaler) {
    'use strict';

    function isPolyfillScaleNeeded(strokeWidth, maintainAspectRatio) {
        // does browser supports css 'vector-effect: non-scaling-stroke'
        const isVectorEffect = warmupUtilsLib.svgFeatureDetection.flags().isVectorEffect;
        return strokeWidth > 0 && !maintainAspectRatio && !isVectorEffect;
    }

    function getSvgMeasures(nodesMap, id) {
        const node = nodesMap[id];

        const {displayMode, strokewidth} = node.dataset;
        const maintainAspectRatio = displayMode !== 'stretch';
        const strokeWidth = parseInt(strokewidth, 10);
        if (isPolyfillScaleNeeded(strokeWidth, maintainAspectRatio)) {
            const requestedSize = {
                width: node.offsetWidth - strokeWidth,
                height: node.offsetHeight - strokeWidth
            };

            return {
                polyfillScale: svgScaler.measure(
                    id,
                    nodesMap,
                    requestedSize,
                    strokeWidth,
                    maintainAspectRatio)
            };
        }
        return null;
    }

    function measureShape(id, nodesMap) {
        if (!nodesMap[id]) {
            return;
        }

        const node = nodesMap[id];
        const svgId = node.getAttribute('data-svg-id');
        const svgElem = node.querySelector('svg');

        if (svgId && svgElem) {
            nodesMap[`${id}svg`] = svgElem;
            return getSvgMeasures(nodesMap, id);
        }

        // find hook and call it
    }

    /**
     *
     * @param id
     * @param patchers
     * @param {layout.structureInfo} structureInfo
     * @param siteData
     */
    function layoutShape(id, patchers, svgMeasures) {
        const polyfillScaleProps = _.get(svgMeasures, 'polyfillScale');
        if (polyfillScaleProps) {
            svgScaler.patch(id, patchers, polyfillScaleProps);
        }
        // else, do nothing
    }

    function layoutAndPatchShapes(shapeNodes, nodesMap) {
        const ids = [];
        const svgMeasuresMap = {};

        _.forEach(shapeNodes, shape => {
            const compId = shape.getAttribute('id');
            nodesMap[compId] = shape;
            svgMeasuresMap[compId] = measureShape(compId, nodesMap);
            ids.push(compId);
        });

        return patchers => {
            _.forEach(ids, compId => layoutShape(compId, patchers, svgMeasuresMap[compId]));
        };
    }

    function layoutShapeNodes(id, nodesMap) {
        if (nodesMap[id].getAttribute('data-svg-id')) {
            return layoutAndPatchShapes([nodesMap[id]], nodesMap);
        }
        const shapeNodes = nodesMap[id].querySelectorAll('*[data-svg-id]');
        return layoutAndPatchShapes(shapeNodes, nodesMap);
    }

    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.VectorImage', layoutShapeNodes);
    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.svgshape.SvgShape', layoutShapeNodes);
    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.PopupCloseIconButton', layoutShapeNodes);
    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.MediaControls', layoutShapeNodes);
    // layout.registerCustomLayoutFunction('wysiwyg.viewer.components.MediaOverlayControls', layoutShapeNodes); // see mediaOverlayControlsLayout
    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.mobile.TinyMenu', layoutShapeNodes);
    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.LoginSocialBar', layoutShapeNodes);
    layout.registerCustomLayoutFunction('wysiwyg.viewer.components.BackToTopButton', layoutShapeNodes);

    return {
        layoutShapeNodes
    };
});
